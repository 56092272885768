// import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Hero from "./components/Hero";
// import ContactUs from "./components/ContactUs";
// import Services from "./components/Services";

const languages = [
  {
    code: "en",
  },
  {
    code: "ar",
    dir: "rtl",
  },
];
function App() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("App_Name");
    console.log(document.description);
  }, [currentLanguage, t]);

  return (
    <>

      <Hero />
      {/* <Services /> */}
      {/* <ContactUs /> */}
      {/* <div>
        {languages.map(({ code, dir }) => (
          <button
            className="border-2 w-1/2 rounded-lg"
            onClick={() => i18next.changeLanguage(code)}
          >
            {code}
          </button>
        ))}
      </div> */}

    </>
  );
}

export default App;
