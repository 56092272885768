import facebook_black_icon from '../assets/images/facebook_black_icon.svg'
import twitter_black_icon from '../assets/images/twitter_black_icon.svg'
import instagram_black_icon from '../assets/images/instagram_black_icon.svg'
import linkedin_black_icon from '../assets/images/linkedin_black_icon.svg'
import only_logo from '../assets/images/2Space_New_Logo.svg'
import whatsapp_icon from '../assets/images/whatsapp_icon.svg'

function Footer() {
    return (
        <>

            {/* ############ mobile ############ */}
            <div className='md:hidden flex flex-col bg-black  rounded-t-[30px] ps-[23px] py-14 text-white'>
                <div className='flex justify-start gap-2 items-center'>
                    <img src={only_logo} alt='only_logo' className='h-[70px] w-[70px] ' />
                    <h1 className='text[28px] font-extrabold'>2Space</h1>
                </div>
                <h1 className='py-6 me-5 font-plus-jakarta-sans-bold'>We offers a comprehensive suite of digital marketing services that cover all aspects of our online presence. From SEO and social media marketing to content creation and PPC advertising, they have the expertise and resources to handle our diverse marketing needs.</h1>
                <div className='flex justify-start gap-4 mb-9'>
                    <img src={facebook_black_icon} alt='facebook' />
                    <img src={twitter_black_icon} alt='twitter' />
                    <img src={linkedin_black_icon} alt='linkedIn' />
                    <img src={instagram_black_icon} alt='instagram' />
                </div>

                <div className='flex flex-col gap-2'>
                    <h1 className='text-[26px] font-semibold'>Quick Links</h1>
                    <div className='flex flex-col text-[15px]'>
                        <a href='/home' className='hover:underline active:underline hover:text-mainOrange'>Home</a>
                        <a href='/about' className='hover:underline active:underline hover:text-mainOrange'>About</a>
                        <a href='/work' className='hover:underline active:underline hover:text-mainOrange'>Work</a>
                        <a href='/services' className='hover:underline active:underline hover:text-mainOrange'>Services</a>
                        <a href='/contact' className='hover:underline active:underline hover:text-mainOrange'>Content</a>
                    </div>
                </div>

                <div className='flex flex-col gap-2 my-7'>
                    <h1 className='text-[26px] font-semibold'>Support</h1>
                    <div className='flex flex-col text-[15px]'>
                        <a href='/Privacy' className='hover:underline active:underline hover:text-mainOrange'>Privacy Policy</a>
                        <a href='/Terms' className='hover:underline active:underline hover:text-mainOrange'>Terms & Conditions</a>
                        <a href='/faqs' className='hover:underline active:underline hover:text-mainOrange'>FAQs</a>
                        <a href='/help' className='hover:underline active:underline hover:text-mainOrange'>Help Center</a>
                    </div>
                </div>

                <div className='flex flex-col gap-2'>
                    <h1 className='text-[26px] font-semibold'>Content</h1>
                    <div className='flex flex-col text-[15px]'>
                        <h1>+966 50 167 9999</h1>
                        <h1>info@2space.agency</h1>
                        <h1>Jeddah, Saudi Arabia</h1>
                    </div>
                </div>
            </div>




            {/* ############ Desktop ############  */}
            <div className='hidden md:block relative bg-black rounded-t-[30px] p-10 text-white'>
                <button>
                    <img className='absolute -top-20' src={whatsapp_icon} alt='whatsapp_icon' />
                </button>
                <div className='flex'>
                    {/* Left Side */}
                    <div className='flex flex-col w-1/2 gap-4'>
                        <div className='flex justify-start gap-5'>
                            <img src={only_logo} alt='only_logo' className='h-[93px] w-[93px] rounded-[10px]' />
                            <h1 className='font-extrabold text-[50px]'>2Space</h1>
                        </div>
                        <h1 className='w-9/12 text-[14px]' >We offers a comprehensive suite of digital marketing services that cover all aspects of our online presence. From SEO and social media marketing to content creation and PPC advertising, they have the expertise and resources to handle our diverse marketing needs.</h1>
                        <div className='flex justify-start gap-4'>
                            <img src={facebook_black_icon} alt='facebook' />
                            <img src={twitter_black_icon} alt='twitter' />
                            <img src={linkedin_black_icon} alt='linkedIn' />
                            <img src={instagram_black_icon} alt='instagram' />
                        </div>
                    </div>
                    {/* Right Side */}
                    <div className='flex justify-center gap-20 w-1/2 mt-6'>
                        <div className='flex flex-col gap-2'>
                            <h1 className='text-[26px] font-semibold'>Quick Links</h1>
                            <div className='flex flex-col gap-1 font-extralight text-[15px]'>
                                <a href='/' className='hover:underline active:underline hover:text-mainOrange'>Home</a>
                                <a href='/about' className='hover:underline active:underline hover:text-mainOrange'>About</a>
                                <a href='/work' className='hover:underline active:underline hover:text-mainOrange'>Work</a>
                                <a href='/services' className='hover:underline active:underline hover:text-mainOrange'>Services</a>
                                <a href='/contact' className='hover:underline active:underline hover:text-mainOrange'>Content</a>
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <h1 className='text-[26px] font-semibold'>Support</h1>
                            <div className='flex flex-col gap-1 font-extralight text-[15px]'>
                                <a href='/privacy' className='hover:underline active:underline hover:text-mainOrange'>Privacy Policy</a>
                                <a href='/terms' className='hover:underline active:underline hover:text-mainOrange'>Terms & Conditions</a>
                                <a href='/faqs' className='hover:underline active:underline hover:text-mainOrange'>FAQs</a>
                                <a href='/help' className='hover:underline active:underline hover:text-mainOrange'>Help Center</a>
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <h1 className='text-[26px] font-semibold'>Content</h1>
                            <div className='flex flex-col gap-1 font-extralight text-[15px]'>
                                <h1>+966 50 167 9999</h1>
                                <h1>info@2space.agency</h1>
                                <h1>Jeddah, Saudi Arabia</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer