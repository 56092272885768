// import hero_image1 from "../Images/hero_img1.png";
// import hero_image2 from "../Images/hero_img2.png";
// import hero_image3 from "../Images/hero_img3.png";
// import hero_animation1 from "../Images/hero_animation1.png";
// import hero_animation2 from "../Images/hero_animation2.png";
// import hero_animation3 from "../Images/hero_animation3.png";
import heroImg from "../assets/images/heroImg.svg";
import progress_bar from "../assets/images/progress_bar.svg";
import heroImg_arrow_with_circle from "../assets/images/heroImg_arrow_with_circle.svg";
import column_chart from "../assets/images/column_chart.svg";
import two_circle_bg_sm from "../assets/images/two_circle_bg_sm.svg";
import two_circle_bg_BIG_svg from "../assets/images/two_circle_bg_BIG.svg";
// import two_circle_bg_BIG_png from "../assets/images/two_circle_bg_BIG.png";
import bg_noise from "../assets/images/bg_noise.png";
import hero_Company01_logo from "../assets/images/hero_Company01_logo.svg";
import hero_Company02_logo from "../assets/images/hero_Company02_logo.svg";
import hero_Company03_logo from "../assets/images/hero_Company03_logo.svg";
import only_arrow_right_white from "../assets/images/only_arrow_right_white.svg";
import right_white_arrow from "../assets/images/right_white_arrow.svg";
import left_black_arrow from '../assets/images/left_black_arrow.svg'
import right_black_arrow from '../assets/images/right_black_arrow.svg'
import orang_qutation_mark from '../assets/images/orang_qutation_mark.svg'
import two_circle_extraLarge from '../assets/images/two_circle_extraLarge.svg'
import top_gray_arrow from '../assets/images/top_gray_arrow.svg'
import top_black_arrow from '../assets/images/top_black_arrow.svg'
import top_pink_arrow from '../assets/images/top_pink_arrow.svg'
import only_arrow_right_black from '../assets/images/only_arrow_right_black.svg'

import { images } from "./Data";

import { useState } from "react";
import HomeFAQs from "./HomeFAQs";
import Navbar from "./Navbar";

function Hero() {
  const [showWAD, setShowWAD] = useState(true);
  const [showWADev, setShowWADev] = useState(true);
  const [showDM, setShowDM] = useState(true);
  const [show_branding, setShow_branding] = useState(true);
  const [show_Prodection, setShow_Prodection] = useState(true);

  const [activeButton, setActiveButton] = useState('all');


  function handleShowWAD() {
    setShowWAD(!showWAD);
    setShowWADev(true)
    setShowDM(true)
    setShow_branding(true)
    setShow_Prodection(true)
  }

  function handleShow_development() {
    setShowWAD(true)
    setShowWADev(!showWADev);
    setShowDM(true)
    setShow_branding(true)
    setShow_Prodection(true)
  }

  function handleShowDM() {
    setShowWAD(true);
    setShowWADev(true)
    setShowDM(!showDM)
    setShow_branding(true)
    setShow_Prodection(true)
  }

  function handleShowBranding() {
    setShowWAD(true);
    setShowWADev(true)
    setShowDM(true)
    setShow_branding(!show_branding)
    setShow_Prodection(true)
  }

  function handleShowProdection() {
    setShowWAD(true);
    setShowWADev(true)
    setShowDM(true)
    setShow_branding(true)
    setShow_Prodection(!show_Prodection)
  }

  return (
    <>
      {/* Desktop Screen */}
      <div className="hidden md:block" >
        <Navbar title="Get Started" />
        <div className="flex justify-between mb-10 mt-[98px] mx-20">
          {/* Texts */}
          <div className="flex flex-col justify-start gap-10">
            <h1 className="text-[4.5rem] whitespace-nowrap leading-tight font-extrabold ">
              Transforming Visions <br /> Into Digital Realities
            </h1>
            <p className="text-paragraghGray text-[16px] font-light">
              At 2Space, our passion is turning aspirations into measurable
              achievements,
              <br /> crafting campaigns that resonate in the ever-evolving digital
              landscape. Join us <br /> on a transformative journey where your
              vision meets the power of cutting-edge <br /> marketing expertise.
            </p>
            <div className="flex justify-start items-center gap-10">
              <button className="flex justify-between items-center bg-black rounded-full px-10 py-3 w-[219px] h-[56px] text-white">
                <h1>Get Started</h1>
                <img src={only_arrow_right_white} alt="right_white_arrow" />
              </button>
              <h1 className="underline underline-offset-4"> View Case Study</h1>
            </div>
            <div className="flex justify-start gap-10">
              <p className="text-[14px] font-semibold text-[#010205]">Trusted by the world's<br /> biggest brands</p>
              <div className="flex justify-center">
                <img className="w-[108.3px] h-[33.32px]" src={hero_Company01_logo} alt="" />
                <img className="w-[108.3px] h-[33.32px]" src={hero_Company02_logo} alt="" />
                <img className="w-[108.3px] h-[33.32px]" src={hero_Company03_logo} alt="" />
              </div>
            </div>
          </div>
          {/* Images */}
          <div className="flex flex-col">
            <div className="flex relative justify-between gap-3">
              <img className="w-[300.14px] h-[272.4px]" src={heroImg} alt="heroImage" />
              <img
                className="absolute left-32 -top-5 w-[106.98px] h-[106.98px]"
                src={heroImg_arrow_with_circle}
                alt="heroImg_arrow_with_circle"
              />
              <div className="flex flex-col items-center bg-mainLightGray rounded-2xl w-[256.55px] h-[278.35px] px-6">
                <h1 className="text-[84px] font-bold w-full"> 230+ </h1>
                <p className="text-[#5C5D5F] w-full">
                  some big companies that <br /> we work with, and trust <br /> us
                  very much
                </p>
                <img className="mt-[48px]" src={progress_bar} alt="progress_bar" />
              </div>

            </div>
            <div className="relative flex justify-center gap-6 bg-black rounded-[20px] w-[582.44px] h-[213.96px] items-center mt-2">
              <img className="absolute left-0 z-0 rounded-3xl h-[213.96px]" src={two_circle_bg_sm} alt="two_circle_bg" />
              <div className="flex flex-col z-10">
                <div className="flex justify-start items-center gap-4">
                  <hr className="border-[1px] w-[54px]" />
                  <p className="text-white text-[14px]">
                    Drive More Traffic and Sales{" "}
                  </p>
                </div>
                <h1 className="text-white text-[32px] font-semibold">
                  Drive more traffic <br /> and product sales
                </h1>
              </div>
              <div className="flex h-full items-end">
                <img
                  className="w-[227px] h-[166px]"
                  src={column_chart}
                  alt="column_chart"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Provide The Best Service Section */}
        <div className="flex justify-start items-center gap-10  bg-mainLightGray rounded-2xl my-28 mx-20 py-14 px-4 ">
          <h1 className="text-[3rem] whitespace-nowrap font-semibold leading-none">Provide the best service <br /> with out of the box ideas</h1>
          <p className="text-[1rem] whitespace-nowrap font-light text-[#878C91]">we are a passionate team of digital marketing enthusiasts <br /> dedicated to helping businesses succeed in the digital world. With <br />  a deep understanding of the ever-evolving online landscape, we<br /> stay at the forefront of industry trends and technologies.</p>
        </div>
        {/* Our Services Section */}
        <div className="relative flex flex-col justify-start items-start bg-[#020609] px-20 py-16 mx-20 rounded-3xl h-[989px]">
          <p className="text-mainOrange z-10">OUR SERVICES</p>
          <h1 className="text-[48px] z-10 font-semibold text-white leading-tight mt-5">Provided Services to <br /> help your business </h1>
          <img className="absolute top-0  right-0 h-[989px] rounded-3xl backdrop-blur-3xl opacity-95" src={two_circle_bg_BIG_svg} alt="two_circle_bg_BIG_svg" />
          <img className="absolute top-0 right-0 h-[989px] opacity-40 rounded-3xl" src={bg_noise} alt="bg_noise" />
          <div className="flex justify-start items-center gap-10 z-10 mt-20">
            <div className="bg-[#864747] w-[533px] h-[622px] rounded-3xl "></div>
            <div className="flex flex-col gap-4">

              {/* Website & App Design */}
              <div className={`flex justify-start items-start text-[32px] font-semibold gap-4 ${showWAD ? "text-white" : "text-black items-start bg-white rounded-2xl p-2  w-[530px] h-48 "}`}>
                <button className="" onClick={handleShowWAD}>{showWAD ? "+" : "-"}</button>
                <div className="flex flex-col ">
                  <h1>Website & App Design </h1>
                  <p className={`text-[16px] ${showWAD ? "hidden" : "visible"}`}> Our web and mobile app developers are committed to <br /> achieving visual and performance excellence. From the <br />  backend to the frontend, we prioritize delivering a <br /> seamless and enjoyable user experience on all devices.</p>
                </div>
              </div>
              {/* Website & App Development */}
              <div className={`flex justify-start items-start text-[32px] font-semibold gap-4 ${showWADev ? "text-white" : "text-black items-start bg-white rounded-2xl p-2  w-[530px] h-48 "}`}>
                <button className="" onClick={handleShow_development}>{showWADev ? "+" : "-"}</button>
                <div className="flex flex-col ">
                  <h1>Website & App Development</h1>
                  <p className={`text-[16px] ${showWADev ? "hidden" : "visible"}`}> Our web and mobile app developers are committed to <br /> achieving visual and performance excellence. From the <br />  backend to the frontend, we prioritize delivering a <br /> seamless and enjoyable user experience on all devices.</p>
                </div>
              </div>
              {/* Digital Marketing */}
              <div className={`flex justify-start items-start text-[32px] font-semibold gap-4 ${showDM ? "text-white" : "text-black items-start bg-white rounded-2xl p-2  w-[530px] h-48 "}`}>
                <button className="" onClick={handleShowDM}>{showDM ? "+" : "-"}</button>
                <div className="flex flex-col ">
                  <h1>Digital Marketing</h1>
                  <p className={`text-[16px] ${showDM ? "hidden" : "visible"}`}> Our web and mobile app developers are committed to <br /> achieving visual and performance excellence. From the <br />  backend to the frontend, we prioritize delivering a <br /> seamless and enjoyable user experience on all devices.</p>
                </div>
              </div>

              {/* Branding */}
              <div className={`flex justify-start items-start text-[32px] font-semibold gap-4 ${show_branding ? "text-white" : "text-black items-start bg-white rounded-2xl p-2  w-[530px] h-48 "}`}>
                <button className="" onClick={handleShowBranding}>{show_branding ? "+" : "-"}</button>
                <div className="flex flex-col ">
                  <h1>Branding</h1>
                  <p className={`text-[16px] ${show_branding ? "hidden" : "visible"}`}> Our web and mobile app developers are committed to <br /> achieving visual and performance excellence. From the <br />  backend to the frontend, we prioritize delivering a <br /> seamless and enjoyable user experience on all devices.</p>
                </div>
              </div>

              {/* Production */}
              <div className={`flex justify-start items-start text-[32px] font-semibold gap-4 ${show_Prodection ? "text-white" : "text-black items-start bg-white rounded-2xl p-2  w-[530px] h-48 "}`}>
                <button className="" onClick={handleShowProdection}>{show_Prodection ? "+" : "-"}</button>
                <div className="flex flex-col ">
                  <h1>Production</h1>
                  <p className={`text-[16px] ${show_Prodection ? "hidden" : "visible"}`}> Our web and mobile app developers are committed to <br /> achieving visual and performance excellence. From the <br />  backend to the frontend, we prioritize delivering a <br /> seamless and enjoyable user experience on all devices.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* Our Work Section */}
        <div className="flex flex-col items-center mt-40">
          <p className="text-mainOrange text-[14px] font-medium">OUR WORK</p>
          <h1 className="font-semibold text-[48px] mb-[45px]">Our Latest Case Studies</h1>
          <div>
            <div className="flex justify-center gap-4  mb-16">
              <button onClick={() => setActiveButton('all')} className={activeButton === 'all' ? 'underline underline-offset-8 text-mainOrange border-[1px] rounded-full px-4 py-[3px]' : ''}>All</button>
              <button onClick={() => setActiveButton('Creative')} className={activeButton === 'Creative' ? 'underline underline-offset-8 text-mainOrange border-[1px] rounded-full px-4 py-[3px]' : ''}>Creative</button>
              <button onClick={() => setActiveButton('Design')} className={activeButton === 'Design' ? 'underline underline-offset-8 text-mainOrange border-[1px] rounded-full px-4 py-[3px]' : ''}>Development</button>
              <button onClick={() => setActiveButton('Digital')} className={activeButton === 'Digital' ? 'underline underline-offset-8 text-mainOrange border-[1px] rounded-full px-4 py-[3px]' : ''}>Digital</button>
              <button onClick={() => setActiveButton('Photography')} className={activeButton === 'Photography' ? 'underline underline-offset-8 text-mainOrange border-[1px] rounded-full px-4 py-[3px]' : ''}>Photography</button>
              <button onClick={() => setActiveButton('Service')} className={activeButton === 'Service' ? 'underline underline-offset-8 text-mainOrange border-[1px] rounded-full px-4 py-[3px]' : ''}>Service</button>

            </div>
            <div className="flex flex-wrap mb-2 items-center justify-center gap-1 ">
              {images[activeButton].map(image => (
                <div className="h-[452.74px] max-w-[521.24px] relative">
                  <img className="h-[452.74px] max-w-[521.24px] rounded-3xl " key={image.id} src={image.src} alt="SomeImage" />
                  <div className="flex justify-between  px-4 absolute bottom-0 bg-white bg-opacity-[24%] backdrop-blur-sm items-center w-full py-4 rounded-3xl ">
                    <div className="flex flex-col  text-white">
                      <h1 className="text-[20px] font-bold ">{image.title}</h1>
                      <p className="text-[14px] font-light mb-[0.625rem]">{image.content}</p>
                      <p className="text-[10px] border-[1px] border-white rounded-full px-2 py-1 w-fit">{image.tag}</p>
                    </div>
                    <img className="" src={right_white_arrow} alt="only_arrow_right_white" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Testmonials */}
        <div className="flex justify-between px-5 items-center bg-mainLightGray mt-[137px] rounded-3xl h-80 mx-20">
          <div className="flex flex-col gap-20">
            <div className="flex justify-start items-center gap-4">
              <div className="rounded-full w-24 h-24 bg-[#D1D1D1]">
                <img src="" alt="Idon't" />
              </div>
              <div className="flex flex-col">
                <h1 className="text-[28px] font-bold">Dean Hasting</h1>
                <p className="text-[18px] ">CEO Hel! Social</p>
              </div>
            </div>
            <div className="flex justify-start gap-5">
              <button>
                <img src={left_black_arrow} alt="left_black_arrow" />
              </button>
              <button>
                <img src={right_black_arrow} alt="right_black_arrow" />
              </button>
            </div>
          </div>
          <h1 className="text-[24px]">Lorem ipsum dolor sit amet, consectetur adipisciing elit.<br />
            Aliquam dictum ullameorper leo eget iaculis. Sed hendrerit<br />
            lectus vitae lacus iaculis, in posuere erat faucibus. Praesent<br />
            finibus interdum nibg, sit amet ullamcorper erat volutpat<br /> non.</h1>
          <div className="h-1/2">
            <img className="" src={orang_qutation_mark} alt="orang_qutation_mark" />
          </div>
        </div>
        {/* Frequently Asked Questions */}
        <HomeFAQs />
        {/* Do You Have an Idea? */}
        <div className=" relative flex justify-between items-center gap-36 bg-black py-20 px-[4.438rem] mx-20 rounded-3xl my-36">
          <p className="text-white  z-10 whitespace-nowrap font-bold text-[64px]">Do you have an idea?<br />Let's discuss it.</p>
          <button className='flex justify-between whitespace-nowrap z-10 gap-11 items-center bg-white rounded-full px-8 py-3 h-full text-black'>
            <h1 className="text-[16px]  font-bold">Get Started</h1>
            <img src={only_arrow_right_black} alt='only_arrow_right_black' />
          </button>
          <img className="absolute bottom-0 h-full left-0 opacity-80 blur-sm rounded-3xl" src={two_circle_extraLarge} alt="two_circle_extraLarge" />
          <img className="absolute top-0 right-0 h-full w-full opacity-40 rounded-3xl" src={bg_noise} alt="bg_noise" />
          <div className=" absolute top-14 left-1/3 flex items-center gap-2">
            <img src={top_gray_arrow} alt="top_gray_arrow" />
            <h1 className="bg-white px-1 text-black rounded-md">UX Researcher</h1>
          </div>
          <div className=" absolute bottom-2/3 left-2/3 flex items-center gap-2">
            <img src={top_pink_arrow} alt="top_gray_arrow" />
            <h1 className="bg-mainOrange px-1 text-black rounded-md">Front-End</h1>
          </div>

          <div className=" absolute bottom-12 left-16 flex items-center gap-2">
            <img src={top_pink_arrow} alt="top_pink_arrow" />
            <h1 className="bg-mainOrange px-1 text-black rounded-md">Copywriter</h1>
          </div>

          <div className=" absolute bottom-16 left-2/4 flex items-center gap-2">
            <img src={top_black_arrow} alt="top_pink_arrow" />
            <h1 className="bg-black px-1 text-white rounded-md">UI Designer</h1>
          </div>
        </div>
      </div>
      {/* Mobile Screen */}
      <div div className="md:hidden" > This is Hero Mobile screen</div>
    </>
  );
}

export default Hero;
