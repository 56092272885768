import React from "react";
import Navbar from "./Navbar";

const Privacy = () => {
  return (
    <>
      {/* Mobile Screen */}
      <div className="md:hidden">This is Privacy Mobile screen</div>
      {/* Desktop Screen */}
      <Navbar title="Schedule Call" />
      <div className="hidden md:flex justify-center relative">
        <h1 className="text-[100px] font-extrabold text-mainOrange mt-[99px] mb-[128px]">Privacy Policy</h1>
        <h1 className="px-4 py-1 text-[14px] bg-black rounded-md text-white absolute top-1/2 left-0 ms-6">Last update: 1 January 2020</h1>
      </div>
      <div className='flex flex-col items-start mx-6 '>
        <h1 className="text-[48px] font-bold">PRIVACY POLICY</h1>
        <h1 className="text-[24px] font-medium my-10">This Privacy Policy describes how your personal information is collected, used, and shared when you visit creativeagencybook.com (the “Site”).</h1>
        <h1 className="text-[48px] font-bold">PERSONAL INFORMATION WE COLLECT</h1>
        <h1 className="text-[24px] font-medium my-10">When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</h1>
        <h1 className="text-[24px] font-medium my-10">We collect Device Information using the following technologies:</h1>

        <div className="text-[24px] font-medium ms-5 mb-[316px]">
          <ul className="list-disc ms-5">
            <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
            <li className="my-3">“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, email, name, phone number, and date/time stamps.</li>
            <li>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</li>
          </ul>
        </div>

      </div>
    </>
  );
};

export default Privacy;