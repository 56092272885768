import React, { useState } from 'react'
import only_arrow_right_white from '../assets/images/only_arrow_right_white.svg'

const HomeFAQs = () => {
    const [show1st, setShow1st] = useState(true);
    const [show2nd, setShow2nd] = useState(true);
    const [show3rd, setShow3rd] = useState(true);
    const [show4th, setShow4th] = useState(true);

    function handleShow1st() {
        setShow1st(!show1st);
        setShow2nd(true)
        setShow3rd(true)
        setShow4th(true)
    }

    function handleShow2nd() {
        setShow1st(true);
        setShow2nd(!show2nd)
        setShow3rd(true)
        setShow4th(true)
    }

    function handleShow3rd() {
        setShow1st(true);
        setShow2nd(true)
        setShow3rd(!show3rd)
        setShow4th(true)
    }

    function handleShow4th() {
        setShow1st(true);
        setShow2nd(true)
        setShow3rd(true)
        setShow4th(!show4th)
    }

    return (
        <div className='flex justify-between items-center mx-20 mt-[184px]'>

            {/* Home FAQS - Left Side */}
            
            <div className='hidden md:flex flex-col gap-5 w-1/2'>

                {/* 1st One */}
                <hr className='border-[1px]  border-[#CBCDCF]' />
                <div className="flex  items-start text-[32px] font-semibold  ">
                    <div className="flex flex-col gap-5">
                        <h1 className=''>Why is digital <b /> marketing important for my business? </h1>
                        <p className={`text-paragraghGray font-light text-[16px] ${show1st ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
                    </div>
                    <button className="ms-5" onClick={handleShow1st}>{show1st ? "+" : "-"}</button>
                </div>
                {/* 2nd One */}
                <hr className='border-[1px]  border-[#CBCDCF]' />
                <div className="flex   items-start text-[32px] font-semibold">
                    <div className="flex flex-col gap-5">
                        <h1 className=''>How can digital marketing help improve my website's visibility?  </h1>
                        <p className={`text-paragraghGray font-light text-[16px] ${show2nd ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
                    </div>
                    <button className="ms-5" onClick={handleShow2nd}>{show2nd ? "+" : "-"}</button>
                </div>
                {/* 3rd One */}
                <hr className='border-[1px]  border-[#CBCDCF]' />
                <div className="flex   items-start text-[32px] font-semibold  ">
                    <div className="flex flex-col gap-5">
                        <h1 className=''>How long does it take to see results from digital marketing efforts? </h1>
                        <p className={`text-paragraghGray font-light text-[16px] ${show3rd ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
                    </div>
                    <button className="ms-5" onClick={handleShow3rd}>{show3rd ? "+" : "-"}</button>
                </div>
                {/* 4th One */}
                <hr className='border-[1px]  border-[#CBCDCF]' />
                <div className="flex   items-start text-[32px] font-semibold  ">
                    <div className="flex flex-col gap-5">
                        <h1 className=''>How do you measure the success of digital marketing campaigns?</h1>
                        <p className={`text-paragraghGray font-light text-[16px] ${show4th ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
                    </div>
                    <button className="ms-5" onClick={handleShow4th}>{show4th ? "+" : "-"}</button>
                </div>

            </div>
            {/* Home FAQS - Right Side */}
            <div className='flex flex-col  gap-5'>
                <p className='text-mainOrange'>FAQS</p>
                <h1 className='text-black font-bold text-[48px] leading-none'>Frequently Asked<br /> Questions</h1>
                <p className=''>we are a passionate team of digital marketing enthusiasts<br /> dedicated to helping businesses succeed in the digital world. With<br />  a deep understanding of the ever-evolving online landscape, we <br />stay at the forefront of industry trends and technologies.</p>
                <div className='flex justify-start items-center gap-20'>
                    <h1 className='underline underline-offset-2'>More FAQS</h1>
                    <button className='flex justify-between items-center bg-black rounded-full px-10 py-3 w-60 h-full text-white'>
                        <h1>Get Started</h1>
                        <img src={only_arrow_right_white} alt='right_white_arrow' />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HomeFAQs