import React, { useState } from "react";
import { images } from "./Data";
import right_white_arrow from '../assets/images/only_arrow_right_white.svg'

const Works = () => {
  const [activeButton, setActiveButton] = useState('all');


  return (
    <>
      {/* Mobile Screen */}
      <div className="md:hidden">This is Works Mobile screen</div>
      {/* Desktop Screen */}
      <div className="hidden md:block">
        {/* Our Work Section */}
        <div className="flex flex-col items-center mt-40">
          <p className="text-mainOrange text-[14px] font-medium">OUR WORK</p>
          <h1 className="font-semibold text-[48px] mb-[45px]">Our Latest Case Studies</h1>
          <div>
            <div className="flex justify-center gap-4  mb-16">
              <button onClick={() => setActiveButton('all')} className={activeButton === 'all' ? 'underline underline-offset-8 text-mainOrange border-2 rounded-full px-4 py-[3px]' : ''}>All</button>
              <button onClick={() => setActiveButton('Creative')} className={activeButton === 'Creative' ? 'underline underline-offset-8 text-mainOrange border-2 rounded-full px-4 py-[3px]' : ''}>Creative</button>
              <button onClick={() => setActiveButton('Design')} className={activeButton === 'Design' ? 'underline underline-offset-8 text-mainOrange border-2 rounded-full px-4 py-[3px]' : ''}>Development</button>
              <button onClick={() => setActiveButton('Digital')} className={activeButton === 'Digital' ? 'underline underline-offset-8 text-mainOrange border-2 rounded-full px-4 py-[3px]' : ''}>Digital</button>
              <button onClick={() => setActiveButton('Photography')} className={activeButton === 'Photography' ? 'underline underline-offset-8 text-mainOrange border-2 rounded-full px-4 py-[3px]' : ''}>Photography</button>
              <button onClick={() => setActiveButton('Service')} className={activeButton === 'Service' ? 'underline underline-offset-8 text-mainOrange border-2 rounded-full px-4 py-[3px]' : ''}>Service</button>

            </div>
            <div className="flex flex-wrap mb-2 items-center justify-center gap-1">
              {images[activeButton].map(image => (
                <div className="h-[452.74px] max-w-[521.24px] relative">
                  <img className="h-[452.74px] max-w-[521.24px] rounded-3xl " key={image.id} src={image.src} alt="SomeImage" />
                  <div className="flex justify-between  px-4 absolute bottom-0 bg-white bg-opacity-[24%] backdrop-blur-sm items-center w-full py-4 rounded-3xl ">
                    <div className="flex flex-col  text-white">
                      <h1 className="text-[20px] font-bold ">{image.title}</h1>
                      <p className="text-[14px] font-light mb-[10px]">{image.content}</p>
                      <p className="text-[10px] border-2 border-white rounded-full px-2 py-1 w-fit">{image.tag}</p>
                    </div>
                    <img className="" src={right_white_arrow} alt="only_arrow_right_white" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Works;
