import React from "react";
import logo from '../assets/images/2Space_New_Logo.svg'
import aboutus_star_icon from '../assets/images/aboutus_star_icon.svg'
import Navbar from "./Navbar";

const AboutUs = () => {
  return (
    <>
      {/* Mobile Screen */}
      <div className="md:hidden">This is Mobile screen</div>
      {/* Desktop Screen */}
      <Navbar title="Schedule Call" />
      <div className="hidden md:flex justify-center relative">
        <h1 className="text-[100px] font-extrabold text-mainOrange mt-[99px] mb-[128px]">ABOUT US</h1>
      </div>
      <div className="flex justify-between mx-6 items-center mb-[157px]">
        <div className='flex flex-col items-start'>
          <h1 className="text-[48px] font-bold">About 2space</h1>
          <h1 className="text-[24px] font-medium w-2/3 my-10">Website and app booking system for their home sWebsite and app booking system for their home service ervice.Website and app booking system for their home service Website and app booking system for their home service Website and app booking system for their home service</h1>
        </div>
        <img src={logo} className="h-[282.61px] w-[274.54px] me-20" alt="only Logo" />
      </div>

      <div className="bg-black rounded-3xl pt-14 pb-28 px-20 mb-12">
        <div className="flex flex-col items-center ">
          <h1 className="text-[48px] font-bold text-mainOrange">Our Values</h1>
          <div className="flex justify-center gap-10 text-[23px] font-bold">
            {/* Left Col */}
            <div className="flex flex-col text-white">
              <img className="my-12 h-[85px] w-[89px]" src={aboutus_star_icon} alt="aboutus_star_icon_left" />
              <h1 className="mb-6">Anything possible for us</h1>
              <h1 className="text-[20px] font-normal ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</h1>
            </div>
            {/* Center Col */}
            <div className="flex flex-col items-center text-white">
              <img className="my-12 h-[85px] w-[89px]" src={aboutus_star_icon} alt="aboutus_star_icon_left" />
              <h1 className="mb-6">Anything possible for us</h1>
              <h1 className="text-[20px] font-normal text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</h1>
            </div>
            {/* Right Col */}
            <div className="flex flex-col items-end text-white">
              <img className="my-12 h-[85px] w-[89px]" src={aboutus_star_icon} alt="aboutus_star_icon_left" />
              <h1 className="mb-6">Anything possible for us</h1>
              <h1 className="text-[20px] font-normal text-end">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</h1>
            </div>
          </div>
        </div>

      </div>

      <div className="flex justify-start items-center gap-16  bg-mainLightGray rounded-2xl my-28 py-14 px-4 ">
        <h1 className="text-[48px] w-1/2 font-semibold leading-none">Provide the best service with out of the box ideas</h1>
        <p className="text-[16px] w-[567px] font-light text-[#878C91]">we are a passionate team of digital marketing enthusiasts dedicated to helping businesses succeed in the digital world. With a deep understanding of the ever-evolving online landscape, we stay at the forefront of industry trends and technologies.</p>
      </div>
    </>
  );
};

export default AboutUs;
