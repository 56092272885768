import React, { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import phone_pink_icon from '../assets/images/phone_pink_icon.svg'
import email_pink_icon from '../assets/images/email_pink_icon.svg'
import only_arrow_right_white from "../assets/images/only_arrow_right_white.svg";
import Navbar from "./Navbar";

const ContactUs = () => {
  const [phoneValue, setPhoneValue] = useState()

  return (
    <>
      {/* Mobile Screen */}
      <div className="md:hidden">This is ContactUs Mobile screen</div>
      {/* Desktop Screen */}
      <Navbar title="Schedule Call" />
      <div className="hidden md:flex justify-between mx-6 mt-24 mb-36">
        <div className="flex flex-col">
          <div className="px-10 py-16 text-[60px] font-bold w-[442px] bg-mainOrange rounded-[40px]">
            <h1 className="leading-tight">Let’s chat, reach out to us 👋</h1>
            <h1 className="text-white text-[16px] font-light mt-9">We would love to help in anyway we can!</h1>
          </div>

          <div className="flex justify-start bg-[#222222] text-white rounded-[30px] py-11 px-12 gap-14 my-6">
            <img src={email_pink_icon} alt="email_pink_icon" />
            <h1>info@2space.agency</h1>
          </div>

          <div className="flex justify-start bg-[#222222] text-white rounded-[30px] py-11 px-12 gap-14">
            <img src={phone_pink_icon} alt="phone_pink_icon" />
            <h1>+966 50 168 9999</h1>
          </div>

        </div>
        <div className="bg-mainLightGray py-14 px-9 rounded-3xl">
          <form className="flex flex-col">

            {/* 1st Row */}
            <div className="flex justify-start gap-8">
              <div className="flex  flex-col">
                <label className="mb-2">First Name</label>
                <input className="rounded-lg w-[309px] ps-5 py-3" placeholder="First Name" />
              </div>
              <div className="flex flex-col">
                <label className="mb-2">Last Name</label>
                <input className="rounded-lg w-[309px] ps-5 py-3" placeholder="Last Name" />
              </div>
            </div>

            {/* 2nd Row */}
            <div className="flex justify-start gap-8 my-9">
              <div className="flex  flex-col">
                <label className="mb-2">Email Address</label>
                <input className="rounded-lg w-[309px] ps-5 py-3" placeholder="Email Address" />
              </div>
              <div className="flex flex-col">
                <label className="mb-2">Phone Number</label>
                <PhoneInput
                  className="bg-white rounded-lg w-[309px] ps-5 py-3"
                  placeholder="Phone Number"
                  value={phoneValue}
                  onChange={setPhoneValue}
                />
              </div>
            </div>

            {/* 3rd Row */}
            <div className="flex  flex-col">
              <label className="mb-2">Message</label>
              <textarea rows={8} className="rounded-lg px-5 py-3" placeholder="Your Message" />
            </div>

            <button className="flex mt-12 justify-between items-center bg-black rounded-full px-10 py-3 w-[219px] h-[56px] text-white">
              <h1>Get Started</h1>
              <img src={only_arrow_right_white} alt="right_white_arrow" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;