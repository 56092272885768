import React, { useEffect, useState } from 'react';
import right_white_arrow from '../assets/images/only_arrow_right_white.svg'
import { useLocation } from 'react-router-dom'
import logo from '../assets/images/only_logo.svg'

function Navbar(pprops, logoImg, dircts) {

    const [scrolling, setScrolling] = useState(false);
    const location = useLocation();
    const myArray = ['/', '/Services', '/Contact-us', '/SpecificWork', '/About-us', '/Work', '/FAQs', '/Privacy',];

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 85) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <nav className={`hidden md:flex sticky top-10 ${scrolling ? 'bg-white backdrop-blur-sm bg-opacity-20' : 'bg-transparent'} z-20  justify-between px-6 py-3 mt-10 mx-20 border-[1px] rounded-full border-mainOrange`}>

                <a className='flex items-center content-center gap-4' href='/'>
                    <img src={logo} alt='logo' className='h-9 rounded-[4px]' />
                    <h1 className='text-[1.375rem]  font-Poppins font-extrabold   '>2Space</h1>
                </a>

                <div className='flex justify-center items-center w-1/2'>

                    <ul className="flex justify-center w-full text-black text-[1rem]">
                        <li className="mx-4 hover:underline hover:text-mainOrange active:underline">
                            <a href="/">
                                <h1 className='font-normal'>Work</h1>
                            </a>
                        </li>
                        <li className="mx-4 hover:underline hover:text-mainOrange active:underline">
                            <a href="/Services">Services</a>
                        </li>
                        <li className="mx-4 hover:underline hover:text-mainOrange active:underline">
                            <a href="/About-us" className='h-full w-full'>About Us</a>
                        </li>
                    </ul>

                    {myArray.includes(location.pathname) ?
                        <>
                            <button className='group bg-black rounded-full px-10 py-3 w-80 h-full text-white'>
                                <a className='flex justify-between items-center' href='/contact'>
                                    <h1>{pprops.title}</h1>
                                    <img className='group-hover:animate-pulse' src={right_white_arrow} alt='right_white_arrow' />
                                </a>
                            </button>

                        </>
                        :
                        <></>
                    }

                </div>
            </nav>
        </>
    );
}

export default Navbar;
