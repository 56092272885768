import React from "react";
import Navbar from "./Navbar";

const Services = () => {
  return (
    <>

      {/* Mobile Screen */}
      <div className="md:hidden">This is Services Mobile screen</div>
      {/* Desktop Screen */}
      <Navbar title="Schedule Call" />
      <div className="hidden md:flex flex-col mb-80">
        <div className="flex justify-center">
          <h1 className="text-[100px] font-extrabold text-mainOrange mt-[99px] mb-[158px]">OUR SERVICES</h1>
        </div>
        {/* Website & App Design */}
        <div className="flex justify-between mx-6">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-[48px]">Website & App Design</h1>
            <p className="font-medium text-[24px] mt-[52px]">In a digital landscape where first impressions<br /> matter, design becomes your competitive edge.<br /> Creating an immersive digital experience  with<br /> seamless navigation and compelling visuals that <br />resonate with your audience and elevate your<br /> brand to new heights.</p>
            <div className="flex flex-col text-[30px] font-medium mt-[85px]">
              <p>- UX Research </p>
              <p>- Wireframes </p>
              <p>- UI Design </p>
              <p>- Prototype </p>
              <p>- Design System </p>
            </div>
          </div>
          <div className="bg-[#D9D9D9] rounded-[30px] h-[718px] w-[542px]">
          </div>
        </div>

        {/* Website & App Development */}
        <div className="flex justify-between mx-6 gap-16 my-[195px]">
          <div className="bg-[#D9D9D9] rounded-[30px] h-[841px] w-[630px]">
          </div>
          <div className="flex flex-col gap-4 ">
            <h1 className="font-bold text-[48px]">Website & App Development</h1>
            <p className="font-medium text-[24px] mt-[52px]">In a digital landscape where first impressions<br /> matter, design becomes your competitive edge.<br /> Creating an immersive digital experience  with<br /> seamless navigation and compelling visuals that <br />resonate with your audience and elevate your<br /> brand to new heights.</p>
            <div className="flex flex-col text-[30px] font-medium mt-[85px]">
              <p>- Front-End </p>
              <p>- Back-End </p>
              <p>- Integration & API </p>
              <p>- E-Commerce </p>
              <p>- Maintenance </p>
              <p>- Hosting </p>
              <p>- IOS & Andriod </p>
            </div>
          </div>
        </div>

        {/* Production */}
        <div className="flex justify-between mx-6">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-[48px]">Production</h1>
            <p className="font-medium text-[24px] mt-[52px]">In a digital landscape where first impressions<br /> matter, design becomes your competitive edge.<br /> Creating an immersive digital experience  with<br /> seamless navigation and compelling visuals that <br />resonate with your audience and elevate your<br /> brand to new heights.</p>
            <div className="flex flex-col text-[30px] font-medium mt-[85px]">
              <p>- Photography </p>
              <p>- Videography </p>
              <p>- 2D motion  Graphics </p>
              <p>- 3D Motion Graphics </p>
              <p>- Animation </p>
            </div>
          </div>
          <div className="bg-[#D9D9D9] rounded-[30px] h-[718px] w-[542px]">
          </div>
        </div>

        {/* Marketing */}
        <div className="flex justify-between mx-6 gap-16 my-[195px]">
          <div className="bg-[#D9D9D9] rounded-[30px] h-[841px] w-[630px]">
          </div>
          <div className="flex flex-col gap-4 ">
            <h1 className="font-bold text-[48px]">Marketing</h1>
            <p className="font-medium text-[24px] mt-[52px]">In a digital landscape where first impressions<br /> matter, design becomes your competitive edge.<br /> Creating an immersive digital experience  with<br /> seamless navigation and compelling visuals that <br />resonate with your audience and elevate your<br /> brand to new heights.</p>
            <div className="flex flex-col text-[30px] font-medium mt-[85px]">
              <p>- Digital Strategy </p>
              <p>- SEO </p>
              <p>- SEM </p>
              <p>- Media Buying </p>
              <p>- Copywriting </p>
              <p>- SMM </p>
              <p>- Content Services </p>
            </div>
          </div>
        </div>


        {/* Branding */}
        <div className="flex justify-between mx-6">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-[48px]">Branding</h1>
            <p className="font-medium text-[24px] mt-[52px]">In a digital landscape where first impressions<br /> matter, design becomes your competitive edge.<br /> Creating an immersive digital experience  with<br /> seamless navigation and compelling visuals that <br />resonate with your audience and elevate your<br /> brand to new heights.</p>
            <div className="flex flex-col text-[30px] font-medium mt-[85px]">
              <p>- Logo Design </p>
              <p>- Brand Guidelines </p>
              <p>- Brand Identity </p>
              <p>- Social Media Kit </p>
            </div>
          </div>
          <div className="bg-[#D9D9D9] rounded-[30px] h-[718px] w-[542px]">
          </div>
        </div>


      </div>
    </>
  );
};

export default Services;
