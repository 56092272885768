import case_study_top_left from "../assets/images/case_study_top_left.svg"
import case_study_top_center from "../assets/images/case_study_top_center.svg"
import case_study_top_right from "../assets/images/case_study_top_right.svg"
import case_study_bottom_left from "../assets/images/case_study_bottom_left.svg"
import case_study_bottom_center from "../assets/images/case_study_bottom_center.svg"
import case_study_bottom_right from "../assets/images/case_study_bottom_right.svg"

// const HomeFAQs_Data = {
//     all: [
//         {
//             id: 1, title: "Why is digital marketing important for my business?",
//             content: "Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights."
//         },
//         {
//             id: 2, title: "How can digital marketing help improve my website's visibility?",
//             content: "Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights."
//         },
//         {
//             id: 3, title: "How long does it take to see results from digital marketing efforts?",
//             content: "Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights."
//         },
//         {
//             id: 4, title: "How do you measure the success of digital marketing campaigns?",
//             content: "Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights."
//         },
//     ]
// }

const servicesData = [
    {
        title: "Title One",
        innerContent: "Content One"
    },
    {
        title: "Title Two",
        innerContent: "Content Two"
    },
]
const images = {
    all: [
        { id: 1, src: case_study_top_left, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 2, src: case_study_top_center, title: "SMPL Burger", content: "Burger restaurant", tag: "Branding" },
        { id: 3, src: case_study_top_right, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 4, src: case_study_bottom_left, title: "Car dar", content: "An auto repair service", tag: "App Development" },
        { id: 5, src: case_study_bottom_center, title: "Lyal", content: "A loyalty Programme", tag: "App Development" },
        { id: 6, src: case_study_bottom_right, title: "Munch", content: "A healthy alternative for food", tag: "Brand Identity" }
    ],
    Creative: [
        { id: 1, src: case_study_top_left, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 2, src: case_study_top_center, title: "SMPL Burger", content: "Burger restaurant", tag: "Branding" },
        { id: 3, src: case_study_top_right, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 4, src: case_study_bottom_left, title: "Car dar", content: "An auto repair service", tag: "App Development" },
        { id: 5, src: case_study_bottom_center, title: "Lyal", content: "A loyalty Programme", tag: "App Development" },
        { id: 6, src: case_study_bottom_right, title: "Munch", content: "A healthy alternative for food", tag: "Brand Identity" }
    ],
    Design: [
        { id: 1, src: case_study_top_left, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 2, src: case_study_top_center, title: "SMPL Burger", content: "Burger restaurant", tag: "Branding" },
        { id: 3, src: case_study_top_right, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 4, src: case_study_bottom_left, title: "Car dar", content: "An auto repair service", tag: "App Development" },
        { id: 5, src: case_study_bottom_center, title: "Lyal", content: "A loyalty Programme", tag: "App Development" },
        { id: 6, src: case_study_bottom_right, title: "Munch", content: "A healthy alternative for food", tag: "Brand Identity" }
    ],
    Digital: [
        { id: 1, src: case_study_top_left, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 2, src: case_study_top_center, title: "SMPL Burger", content: "Burger restaurant", tag: "Branding" },
        { id: 3, src: case_study_top_right, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 4, src: case_study_bottom_left, title: "Car dar", content: "An auto repair service", tag: "App Development" },
        { id: 5, src: case_study_bottom_center, title: "Lyal", content: "A loyalty Programme", tag: "App Development" },
        { id: 6, src: case_study_bottom_right, title: "Munch", content: "A healthy alternative for food", tag: "Brand Identity" }
    ],
    Photography: [
        { id: 1, src: case_study_top_left, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 2, src: case_study_top_center, title: "SMPL Burger", content: "Burger restaurant", tag: "Branding" },
        { id: 3, src: case_study_top_right, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 4, src: case_study_bottom_left, title: "Car dar", content: "An auto repair service", tag: "App Development" },
        { id: 5, src: case_study_bottom_center, title: "Lyal", content: "A loyalty Programme", tag: "App Development" },
        { id: 6, src: case_study_bottom_right, title: "Munch", content: "A healthy alternative for food", tag: "Brand Identity" }
    ],
    Service: [
        { id: 1, src: case_study_top_left, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 2, src: case_study_top_center, title: "SMPL Burger", content: "Burger restaurant", tag: "Branding" },
        { id: 3, src: case_study_top_right, title: "Spa On Air", content: "A home spa service", tag: "Website Development" },
        { id: 4, src: case_study_bottom_left, title: "Car dar", content: "An auto repair service", tag: "App Development" },
        { id: 5, src: case_study_bottom_center, title: "Lyal", content: "A loyalty Programme", tag: "App Development" },
        { id: 6, src: case_study_bottom_right, title: "Munch", content: "A healthy alternative for food", tag: "Brand Identity" }
    ]
};

export { servicesData, images }