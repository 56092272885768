import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import Works from "./components/Works";
import Footer from "./components/Footer";
import FAQs from "./components/FAQs";
import Privacy from "./components/Privacy";
import AboutUs from "./components/AboutUs";
import SpecificWork from "./components/SpecificWork";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: "en",
    detection:
    {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* <Navbar /> */}
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Contact-us" element={<ContactUs />} />
      <Route path="/SpecificWork" element={<SpecificWork />} />
      <Route path="/About-us" element={<AboutUs />} />
      <Route path="/Work" element={<Works />} />
      <Route path="/FAQs" element={<FAQs />} />
      <Route path="/Privacy" element={<Privacy />} />
      <Route path="/work" element={<Works />} />

    </Routes>
    <Footer />
  </BrowserRouter>
);