import React, { useState } from "react";
import Navbar from "./Navbar";

const FAQs = () => {
  const [show1st, setShow1st] = useState(true);
  const [show2nd, setShow2nd] = useState(true);
  const [show3rd, setShow3rd] = useState(true);
  const [show4th, setShow4th] = useState(true);

  function handleShow1st() {
    setShow1st(!show1st);
    setShow2nd(true)
    setShow3rd(true)
    setShow4th(true)
  }

  function handleShow2nd() {
    setShow1st(true);
    setShow2nd(!show2nd)
    setShow3rd(true)
    setShow4th(true)
  }

  function handleShow3rd() {
    setShow1st(true);
    setShow2nd(true)
    setShow3rd(!show3rd)
    setShow4th(true)
  }

  function handleShow4th() {
    setShow1st(true);
    setShow2nd(true)
    setShow3rd(true)
    setShow4th(!show4th)
  }

  return (
    <>
      {/* Mobile Screen */}
      <div className="md:hidden">This is FAQs Mobile screen</div>
      {/* Desktop Screen */}
      <Navbar title="Schedule Call" />
      <div className="hidden md:flex justify-center">
        <h1 className="text-[100px] font-extrabold text-mainOrange mt-[99px] mb-[158px]">FAQS</h1>
      </div>
      <div className='flex items-center mx-6 '>

        {/* Home FAQS - Left Side */}
        <div className='flex flex-col w-full gap-5 mb-[316px]'>

          {/* 1st One */}
          <hr className='border-[1px] w-full  border-[#CBCDCF]' />
          <div className="flex justify-between items-start text-[32px] font-semibold  ">
            <div className="flex flex-col gap-5">
              <h1 className=' w-full'>Why is digital marketing important for my business? </h1>
              <p className={`text-paragraghGray font-light text-[16px] ${show1st ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
            </div>
            <button className="" onClick={handleShow1st}>{show1st ? "+" : "-"}</button>
          </div>
          {/* 2nd One */}
          <hr className='border-[1px]  border-[#CBCDCF]' />
          <div className="flex justify-between items-start text-[32px] font-semibold  ">
            <div className="flex flex-col gap-5">
              <h1 className=''>Why is digital marketing important for my business? </h1>
              <p className={`text-paragraghGray font-light text-[16px] ${show2nd ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
            </div>
            <button className="" onClick={handleShow2nd}>{show2nd ? "+" : "-"}</button>
          </div>
          {/* 3rd One */}
          <hr className='border-[1px]  border-[#CBCDCF]' />
          <div className="flex justify-between items-start text-[32px] font-semibold  ">
            <div className="flex flex-col gap-5">
              <h1 className=''>Why is digital marketing important for my business? </h1>
              <p className={`text-paragraghGray font-light text-[16px] ${show3rd ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
            </div>
            <button className="" onClick={handleShow3rd}>{show3rd ? "+" : "-"}</button>
          </div>
          {/* 4th One */}
          <hr className='border-[1px]  border-[#CBCDCF]' />
          <div className="flex justify-between items-start text-[32px] font-semibold  ">
            <div className="flex flex-col gap-5">
              <h1 className=''>Why is digital marketing important for my business? </h1>
              <p className={`text-paragraghGray font-light text-[16px] ${show4th ? "hidden" : "visible"}`}> Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.</p>
            </div>
            <button className="" onClick={handleShow4th}>{show4th ? "+" : "-"}</button>
          </div>

        </div>

      </div>
    </>
  );
};

export default FAQs;
