import React from "react";
import Navbar from "./Navbar";

const SpecificWork = () => {
  return (
    <>
      {/* Mobile Screen */}
      <div className="md:hidden">This is SpecificWork Mobile screen</div>
      {/* Desktop Screen */}
      <Navbar title="Schedule Call" />
      <div className="hidden md:flex flex-col mx-6">
        <h1 className="text-mainOrange text-[14px] font-medium mt-16">Project</h1>
        <div className="flex justify-start gap-7 items-start mt-7 mb-14">
          <h1 className="text-[100px] font-extrabold leading-[4rem]">SPA ON AIR</h1>
          <h1 className="text-[16px] font-light">Salon home service</h1>
        </div>
        <h1 className="text-[24px] font-medium mb-16">Website and app booking system for their home service </h1>

        <div className="flex justify-between gap-14">
          <div className="rounded-[30px] bg-[#D9D9D9] h-[609px] w-[611px]"></div>
          <div className="rounded-[30px] bg-[#D9D9D9] h-[609px] w-[611px]"></div>
        </div>

        <div className="flex justify-center gap-60 mb-32 mt-28">

          <div className="flex flex-col">
            <h1 className="text-[26px] font-bold">Client</h1>
            <h1 className="text-[16px] font-normal">Spa on air</h1>
          </div>

          <div className="flex flex-col">
            <h1 className="text-[26px] font-bold">Services</h1>
            <h1 className="text-[16px] font-normal">Website development </h1>
            <h1 className="text-[16px] font-normal">UI Design</h1>
            <h1 className="text-[16px] font-normal">Branding</h1>
          </div>

          <div className="flex flex-col">
            <h1 className="text-[26px] font-bold">Industry</h1>
            <h1 className="text-[16px] font-normal">Beauty</h1>
          </div>

          <div className="flex flex-col">
            <h1 className="text-[26px] font-bold">Date</h1>
            <h1 className="text-[16px] font-normal">Jan 2024’</h1>
          </div>
        </div>

        <h1 className="text-[48px] font-bold mb-11">What is Spa on air?</h1>
        <h1 className="text-[16px] font-light mb-20">Website and app booking system for their home sWebsite and app booking system for their home service ervice.Website and app booking system for their home service Website and app booking system for their home service Website and app booking system for their home service  </h1>

        <div className="bg-[#D9D9D9] h-[482px] rounded-[30px] mb-28"></div>

        <div className="flex justify-between gap-12">
          <div className="flex flex-col w-2/3">
            <h1 className="text-[48px] font-bold mb-11">Challenge:</h1>
            <h1 className="text-[24px] font-medium mb-20">Website and app booking system for their home sWebsite and app booking system for their home service ervice.Website and app booking system for their home service Website and app booking system for their home service Website and app booking system for their home service  </h1>

            <h1 className="text-[48px] font-bold mb-11">Solution:</h1>
            <h1 className="text-[24px] font-medium mb-20">Website and app booking system for their home sWebsite and app booking system for their home service ervice.Website and app booking system for their home service Website and app booking system for their home service Website and app booking system for their home service  </h1>
          </div>

          <div className="bg-[#D9D9D9] h-[605px] rounded-[30px] w-[493px]"></div>

        </div>

      </div>
    </>
  );
};

export default SpecificWork;
